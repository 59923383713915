<template>
  <b-nav-item v-if="isAdministrator" @click="redirectEcommerceShop" title="Productos">
    <feather-icon size="21" icon="ShoppingCartIcon"/>
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'

export default {
  name: 'SearchProductToggler',
  components: {
    BNavItem,
  },
  computed: {
    isOnlyAdmin() {
      const user = this.$store.getters['auth/getUser']
      return user && (user.customRol.id === '849fb91e-cab4-4894-a1c3-aedf03b47c34' || user.customRol.id === "2d7f49c8-ad48-4c4f-8def-fb06fc68143c")
    },
    isAdministrator() {
      return store.getters['auth/getUser'] ? 
        (store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] || store.getters['auth/getUser'].customRol.id == store.getters['auth/getDirectorRol']) : false
    },
  },
  setup() {
    
    const redirectEcommerceShop = () => {
      if (router.currentRoute.name === 'apps-e-commerce-shop') return
      router.push({ name: 'apps-e-commerce-shop' })
    }

    return { 
      redirectEcommerceShop
    }
  },
}
</script>
