import axios from '@/libs/axios'

export default class Articles {

    PorConexionYArticuloId(idEmpresa, idArticulo) {
        return axios.get(`/Articulos/PorConexionYArticuloId/${idEmpresa}/${idArticulo}`)
    }

    getCustomRelationAll(payload) {
        return axios.get(`/Articulos/ObtenerTodosParaRelacion`, {params: payload})
    }

    saveCustomRelation(payload) {
        return axios.post(`/Articulos/AgregarRelacionArticulos`, payload)
    }

    getAllForSettingRelations (query) {
        return axios.get(`Articulos/ObtenerTodosParaEstablecerRelaciones`, {params: query})
    }

    unlinkedRelationShip(idEmpresa, relArtId) {
        return axios.delete(`Articulos/DesvincularRelacionArticulos/${idEmpresa}/${relArtId}`)
    }

    findByPrimaryKey(idEmpresa, claveArticulo, precioLista) {
        return axios.get(`/Articulos/PorClavePrimaria/${idEmpresa}/${claveArticulo}/${precioLista}`)
    }
    
    getArticlesByKeysAsync(companyId1, articleKey1, priceList1, companyId2, articleKey2, priceList2) {
        return axios.get(`/Articulos/PorClaves/${companyId1}/${articleKey1}/${priceList1}/${companyId2}/${articleKey2}/${priceList2}`)
    }

    getAllLinesArticles(query) {
        return axios.get(`/Articulos/ObtenerLineasArticulos`, { params: query })
    }

    getAllGroupLinesArticles(query) {
        return axios.get(`/Articulos/ObtenerGrupoLineasArticulos`, { params: query })
    }

    updateOrCreatePricesArticles(payload) {
        return axios.post(`/Articulos/ActualizarCrearPreciosArticulos`, payload)
    }

    searchArticles(query) {
        return axios.get(`/Articulos/SearchArticles`, { params: query})
    }

    searchArticlesByQr(query) {
        return axios.get(`/Articulos/SearchArticleByQr`, { params: query})
    }
    
    
}
